import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './Header.css';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Menu } from './menu.svg';
import { stack as MobileMenu } from 'react-burger-menu';
import {
  NavLink, useHistory,
  BrowserRouter as Router,
} from 'react-router-dom';

const Header = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  $(function () {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 5) {
        $(".menuNavigation").addClass("white");
      } else {
        $(".menuNavigation").removeClass("white");
      }
    });
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const activeLinkStyle = {
    fontWeight: 'bold',
    fontFamily: 'tit-bold',
    color: '#00CCFF'
  };

  return (

    <>
      <div className="hidemobile">
        <div className="menuicon">
          <Menu onClick={toggleDropdown} />
        </div>
        <MobileMenu className="mobilemenu">
          <NavLink className="firstmobileitem" to="/" exact activeStyle={activeLinkStyle}>Home</NavLink>
          <div className="dropdown">
            <NavLink to="/services" activeClassName="active-link" className="alink">
            Services <span className="arrowhead" ></span>
            </NavLink>
            <div className="dropdown-content">
            <NavLink className='navlink'  to="/Services" activeClassName="active-link">Explore Services</NavLink>
              <NavLink className='navlink' to="/BoilerScanX" activeClassName="active-link">BoilerScan X</NavLink>
              <NavLink className='navlink' to="/PhasedArray" activeClassName="active-link">Phased Array</NavLink>
              <NavLink className='navlink' to="/NearDrum" activeClassName="active-link">Near Drum</NavLink>
              <NavLink className='navlink' to="/Iris" activeClassName="active-link">Iris</NavLink>
              <NavLink className='navlink' to="/CorMap" activeClassName="active-link">Corrosion Mapping</NavLink>
            </div>
          </div>
          <NavLink to="/about" activeClassName="active-link">About</NavLink>
          <NavLink to="/contact" activeClassName="active-link">Contact</NavLink>
        </MobileMenu>
      </div>

    <div className="menuNavigation">
      <NavLink to="/" className="logosection">
        <Logo className="logo" />
      </NavLink>

            {/* Mobile menu */}
      


        {/* Desktop menu */}
      <div className="navigation">
        <div className="clientlink">
          <button className="btnclientlogin" onClick={() => history.push("/ClientLogin")} >
            <span>Client Login</span>
          </button>
        </div>
        <div className="centeredLinks">
          <NavLink to="/contact" className="link" activeClassName="active-link">
            Contact
          </NavLink>
          <NavLink to="/about" className="link" activeClassName="active-link">
            About
          </NavLink>
          <div className="dropdown">
            <NavLink to="/services" className="link" activeClassName="active-link">
              Services <span className="arrowhead" activeClassName="active-link"></span>
            </NavLink>
            <div className="dropdown-content">
              <NavLink className='navlink navlink1' to="/Services">Explore Services</NavLink>
              <NavLink className='navlink navlink2' to="/BoilerScanX">BoilerScan X</NavLink>
              <NavLink className='navlink navlink3' to="/PhasedArray">Phased Array</NavLink>
              <NavLink className='navlink navlink4' to="/NearDrum">Near Drum</NavLink>
              <NavLink className='navlink navlink5' to="/Iris">Iris</NavLink>
              <NavLink className='navlink navlink6' to="/CorMap">Corrosion Mapping</NavLink>
            </div>
          </div>
          <NavLink to="/" className="link" id="home" exact activeStyle={activeLinkStyle} >
            Home
          </NavLink>
        </div>
      </div>
      <div className="radialBorder"></div>
    </div>
    </>
  );
};

export default Header;
